import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "image" ]

  next() {
    this.index++
    if (this.index == this.imageTargets.length) {
      this.index = 0
    }
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.imageTargets.forEach((element, index) => {
      if (index != this.index) {
        element.classList.add("opacity-0")
        element.classList.remove("opacity-100")
      } else {
        element.classList.add("opacity-100")
        element.classList.remove("opacity-0")
      }
    })
  }

  connect() {
    setInterval(() => this.next(), 5000)
  }

  initialize() {
    this.index = 0
    this.showCurrentSlide()
  }
}
